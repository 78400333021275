/* eslint-disable global-require */
<template>
  <div class="contact">
    <img
      :src="require('@/assets/images/tourismeBenin/creds.jpg')"
      alt=""
      class="back-image"
    >
    <div class="filigrane-color" />
    <div class="contact-wrapper">
      <!-- <div class="left-infos">
        <h1>Vous avez une question ?</h1>
        <p>
          Merci de nous contacter en écrivant à cette adresse email:
          mtca.dsisa@gouv.bj. <br />
          Nous mettons tout en oeuvre pour répondre le plus rapidement possible.
        </p>
        <h3>
          Heures d'Ouverture : <br />
          Lundi - Vendredi 8h - 17h30
        </h3>
      </div> -->
      <div class="contact-form">
        <!-- <router-link to="/" class="logo mx-auto">
          <img
            :src="require('@/assets/images/logo/logo_mtca.png')"
            alt="logo"
          />
        </router-link> -->
        <h1>{{ $t('claimsPage.title') }}</h1>

        <validation-observer ref="formRules">
          <div class="contact-names-email">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
            >
              <b-form-group
                class="w-100"
                :label="$t('claimsPage.firstname')"
                label-for="firstName"
              >
                <template v-slot:label>
                  <span>{{ $t('claimsPage.firstname') }}
                    <RequiredStar />
                  </span>
                </template>
                <b-form-input
                  id="firstName"
                  v-model="objectMsg.nom"
                  :placeholder="$t('claimsPage.firstnamePlaceholder')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              rules="required"
            >
              <b-form-group
                class="w-100"
                :label="$t('claimsPage.lastname')"
                label-for="lastName"
              >
                <template v-slot:label>
                  <span>{{ $t('claimsPage.lastname') }}
                    <RequiredStar />
                  </span>
                </template>
                <b-form-input
                  id="lastName"
                  v-model="objectMsg.prenom"
                  :placeholder="$t('claimsPage.lastnamePlaceholder')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </div>
          <div class="contact-names-email">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
            >
              <b-form-group
                class="w-100 "
                :label="$t('claimsPage.number')"
                label-for="numero"
              >
                <template v-slot:label>
                  <span>{{ $t('claimsPage.number') }}
                    <RequiredStar />
                  </span>
                </template>
                <vue-tel-input
                  id="numero"
                  v-model="objectMsg.numero"
                  v-phonenumber-field
                  :input-options="inputOptions"
                  default-country="BJ"
                  :placeholder="$t('claimsPage.numberPlaceholder')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              rules="required|email"
            >
              <b-form-group
                class="w-100"
                :label="$t('claimsPage.mail')"
                label-for="email"
              >
                <template v-slot:label>
                  <span>{{ $t('claimsPage.mail') }}
                    <RequiredStar />
                  </span>
                </template>
                <b-form-input
                  id="email"
                  v-model="objectMsg.mail"
                  :placeholder="$t('claimsPage.mailPlaceholder')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </div>
          <b-form-group
            class="mb-1"
            :label="$t('claimsPage.operators')"
            label-for="profil"
          >
            <template v-slot:label>
              <span>{{ $t('claimsPage.operators') }}
                <RequiredStar />
              </span>
            </template>
            <div
              :class="{
                hideInputOperator: hideInputOperator,
                'operator-search': !hideInputOperator,
              }"
            >
              <b-form-input
                id="profil"
                v-model="searchedProfile"
                :placeholder="$t('claimsPage.operatorsPlaceholder')"
                class="m-0"
                debounce="500"
                style="margin-bottom: 7px"
              />
              <b-list-group
                v-if="fetchedEtablissementProfiles || showListOfProfiles"
                ref="listOfProfiles"
                class="custom-list-group w-100 shadow-sm"
              >
                <b-list-group-item
                  v-for="(profile, index) in fetchedEtablissementProfiles"
                  :key="index"
                  class="list-item"
                  @click="selectedTheProfile(profile)"
                >{{ profile.nom_etablissement }}</b-list-group-item>
              </b-list-group>
              <div
                v-if="isSearchEtablissementProfiles"
                class="operator-spinner"
              >
                <b-spinner small />
              </div>
            </div>
            <small
              v-if="noOperator"
              class="text-danger"
            >{{
              $t('claimsPage.operatorError')
            }}</small>
          </b-form-group>

          <b-card
            v-if="selectedProfile"
            class="mb-1 mt-1 border-primary relative"
          >
            <div
              class="d-flex justify-content-end"
              style="position: absolute; left: 0; right: 0"
            >
              <b-button
                v-if="selectedProfile"
                class="btn-icon closeOperatorCard mr-2"
                size="sm"
                variant="danger"
                @click="removeSelectedProfile()"
              >
                <feather-icon
                  icon="XIcon"
                  class="d-inline"
                />
              </b-button>
            </div>
            <div>
              {{ $t('claimsPage.etabName') }}:
              <b>{{ selectedProfile.nom_etablissement }}</b> <br>
              {{ $t('claimsPage.etabType') }}:
              <b> {{ metierComputed.title }} </b> <br>
            </div>
            <hr>
            <b-form-group
              v-if="selectedProfile"
              :label="$t('claimsPage.faultName')"
              :description="$t('claimsPage.faultNameDesc')"
              label-for="culprit"
            >
              <b-form-input
                id="culprit"
                v-model="objectMsg.culprit"
                :placeholder="$t('claimsPage.faultNamePlaceholder')"
                type="text"
              />
            </b-form-group>
          </b-card>

          <!-- <h5  v-if="selectedProfile">
            <b-badge variant="primary">{{
              selectedProfile.nom_etablissement
            }}</b-badge>
            <b-badge class="ml-1" variant="warning">{{
              metierComputed.title
            }}</b-badge>
          </h5> -->
          <!-- <div
            v-if="!noOperator && !selectedProfile"
            class="d-inline-block mb-1"
          ></div> -->
          <!-- ######################################### -->
          <!-- ######################################### -->
          <!-- ######################################### -->
          <!-- ######################################### -->
          <!-- ######################################### -->
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <b-form-group
              :label="$t('complaints.complaint_list.filters.object')"
              label-for="object"
            >
              <template v-slot:label>
                <span>{{ $t('complaints.complaint_list.filters.object') }}
                  <RequiredStar />
                </span>
              </template>
              <v-select
                id="object"
                v-model="msgObjectSelected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="listObjets"
                label="title"
                :reduce="(option) => option.title"
                :placeholder="$t('complaints.complaint_list.filters.object_placeholder')
                "
              />
              <small
                v-if="noObjectSelected"
                class="text-danger"
              >{{
                $t('claimsPage.claimObjectPlaceholderError')
              }}</small>
            </b-form-group>
          </validation-provider>
          <b-form-group
            v-if="showOtherSubject"
            :label="$t('claimsPage.specificObject')"
            label-for="sujet"
          >
            <template v-slot:label>
              <span>{{ $t('claimsPage.specificObject') }}
                <RequiredStar />
              </span>
            </template>
            <validation-provider
              v-slot="{ errors }"
              rules="required"
            >
              <b-form-input
                id="sujet"
                v-model="objectMsg.sujet"
                :placeholder="$t('claimsPage.specificObjectPlaceholder')"
                type="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            :label="$t('claimsPage.attachedFiles')"
            label-for="jointFiles"
          >
            <b-form-file
              id="jointFiles"
              v-model="objectMsg.jointFiles"
              multiple
              :placeholder="$t('claimsPage.attachedFilesPlaceholder')"
              :drop-placeholder="$t('claimsPage.attachedFilesPlaceholderDrop')"
              @change="handleFileChange"
            />
          </b-form-group>

          <div class="mt-1">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
            >
              <b-form-group
                :label="$t('claimsPage.message')"
                label-for="message"
              >
                <template v-slot:label>
                  <span>{{ $t('claimsPage.message') }}
                    <RequiredStar />
                  </span>
                </template>
                <b-form-textarea
                  id="message"
                  v-model="objectMsg.message"
                  :placeholder="$t('claimsPage.messagePlaceholder')"
                  class="w-100"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </div>
          <b-form-group :label="$t('claimsPage.mark')">
            <b-form-rating
              v-model="objectMsg.rating"
              variant="primary"
              inline
            />
          </b-form-group>
          <!-- <b-form-rating show-clear variant="danger">
            <feather-icon slot="icon-empty" icon="FrownIcon" size="18" />
            <feather-icon slot="icon-half" icon="MehIcon" size="18" />
            <feather-icon
              slot="icon-full"
              icon="SmileIcon"
              size="18"
              class="text-success"
            />
            <feather-icon slot="icon-clear" icon="XCircleIcon" size="18" />
          </b-form-rating> -->
          <div class="mt-2 d-flex justify-content-center">
            <!-- :disabled="
                isSending ||
                !selectedProfile ||
                !objectMsg.jointFiles ||
                objectMsg.nom.length === 0 ||
                objectMsg.prenom.length === 0 ||
                objectMsg.mail.length === 0 ||
                objectMsg.sujet.length === 0 ||
                objectMsg.message.length === 0 ||
                objectMsg.numero.length === 0
              " -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              :disabled="isSending"
              class="d-flex align-items-center"
              @click="envoyer()"
            >
              <b-spinner
                v-if="isSending"
                small
                style="margin-right: 5px"
              />
              {{ $t('button.send') }}
            </b-button>
          </div>
        </validation-observer>
      </div>
      <b-modal
      centered
      id="modal-info-show"
      ref="modal-info-show"
      class="d-flex justify-content-center align-items-center"
      size="lg"
      title="Information"
      ok-only
    >
    <span>{{ $t('plain_info.part_1') }}</span><br>
    <span>{{ $t('plain_info.part_2') }}</span>
    </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BBadge,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BForm,
  BInputGroup,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormRating,
  BCardText,
  BSpinner,
  BInputGroupPrepend,
  BButton,
  BCardHeader,
  BFormTextarea,
  BListGroup,
  BListGroupItem,
  BModal,
  VBModal,
} from 'bootstrap-vue'

import { onClickOutside } from '@vueuse/core'

// import utilsService from '@/services/utils/utils.service';

import TipsModalCreateFromLastRecord from '@/components/TipsModalCreateFromLastRecord.vue'
import localstorageService from '@/services/localstorage/localstorage.service'
import { mapMutations, mapActions } from 'vuex'
import vSelect from 'vue-select'
import locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { quillEditor } from 'vue-quill-editor'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// const kb = utilsService.getListFormulaires()
// --------------------------------------------------
import plaintesStoreModule from '@/store/plaintes'
import etablissementStoreModule from '@/store/etablissements'
import RequiredStar from '@/components/RequiredStar.vue'
import { VueTelInput } from 'vue-tel-input'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

export default {
  components: {
    BBadge,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormRating,
    BFormFile,
    BButton,
    BSpinner,
    BCardHeader,
    BFormTextarea,
    BListGroup,
    BListGroupItem,
    BModal,
    VBModal,
    ValidationProvider,
    ValidationObserver,
    VueTelInput,
    TipsModalCreateFromLastRecord,
    vSelect,
    quillEditor,
    locale,
    RequiredStar,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  setup() {
    // const editorOption = {
    //   modules: {
    //     toolbar: '#quill-toolbar',
    //   },
    //   placeholder: 'Description de votre message',
    // };
    // const formulairesByGroup = utilsService.getListFormulairesByGroup();
    // const appLocales = [
    //   { code: 'en', name: 'anglais' },
    //   { code: 'fr', name: 'francais' },
    // ];

    const requiredStoreModules = [
      { path: 'plainte', module: plaintesStoreModule },
      { path: 'etablissement', module: etablissementStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules }
  },
  data() {
    return {
      inputOptions: {
        showDialCode: true,
      },
      typesEts: null,
      msgObjectSelected: '',
      noObjectSelected: false,
      objectMsg: {
        nom: '',
        prenom: '',
        jointFiles: null,
        mail: '',
        sujet: '',
        message: '',
        numero: '',
        culprit: '',
        rating: '',
      },
      listObjets: [
        { id: '1', title: 'Retard du moyen de transport' },
        { id: '2', title: 'Service client irrespectueux' },
        { id: '3', title: 'Problèmes de réservation' },
        { id: '4', title: 'Guide mal informé' },
        { id: '5', title: 'Itinéraire de visite insatisfaisant' },
        { id: '6', title: 'Guide irrespectueux ou désintéressé.' },
        { id: '7', title: 'Réservation annulée sans préavis' },
        { id: '8', title: 'Problèmes de facturation ou de paiement' },
        { id: '9', title: 'Bruit excessif' },
        { id: '10', title: 'Service lent ou inattentif' },
        { id: '11', title: 'Commande incorrecte' },
        { id: '12', title: "Problèmes d'hygiène ou de salubrité" },
        { id: '13', title: 'Autre...' },
      ],
      showOtherSubject: false,
      isSending: false,
      isSearchEtablissementProfiles: false,
      fetchedEtablissementProfiles: [],
      showListOfProfiles: false,
      searchedProfile: '',
      selectedProfile: null,
      noOperator: false,
      launchVerification: false,
    }
  },

  computed: {
    hideInputOperator() {
      return this.selectedProfile !== null
    },
    metierComputed() {
      if (this.typesEts && this.selectedProfile) {
        return this.typesEts.find(
          e => e.id === this.selectedProfile.secteur_id,
        )
      }
    },

    // ...mapState("auth", {
    //   isAuthenticated: "isAuthenticated",
    // }),
    // filteredKB() {
    //   const knowledgeBaseSearchQueryLower =
    //     this.knowledgeBaseSearchQuery.toLowerCase();
    //   // if(this.selectedSectorId){ // filtre choix de secteur
    //   return this.kb.filter((item) => {
    //     const condition =
    //       (this.selectedSectorId
    //         ? item.sector == this.selectedSectorId
    //         : true) &&
    //       item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower);
    //     return condition;
    //   });
    // },
    // currentLocaleName() {
    //   if (this.$i18n.locale === "fr") return "francais";
    //   return "anglais";
    // },
  },
  watch: {
    msgObjectSelected: {
      handler(val, old) {
        if (val === 'Autre...') {
          this.noObjectSelected = false
          this.objectMsg.sujet = ''
          this.showOtherSubject = true
        }
        if (val !== 'Autre...') {
          this.noObjectSelected = false
          this.showOtherSubject = false
          this.objectMsg.sujet = val
        }
      },
    },
    searchedProfile: {
      handler(val, old) {
        if (val.length < 1) return
        this.fetchEtablissementsProfiles(val)
      },
    },
  },
  beforeDestroy() {
    // unregisterStoreModule(this.requiredStoreModules);
  },
  mounted() {
    this.showModal()
    this.getListTypesEtablissement()
    const { listOfProfiles } = this.$refs
    onClickOutside(listOfProfiles, () => {
      this.searchedProfile = ''
      this.fetchedEtablissementProfiles = []
      this.showListOfProfiles = false
    })
    // console.log(this.formulairesByGroup);
    if (localstorageService.getUserId()) {
      this.kbOptions = { publicOnly: false, privateOnly: true }
    } else {
      this.kbOptions = { publicOnly: true, privateOnly: false }
    }
    // this.loadSectors();
  },
  methods: {
    ...mapActions('plainte', {
      action_createPlainte: 'createPlainte',
    }),
    ...mapActions('etablissement', {
      action_searchEtablissement: 'searchEtablissement',

      action_getListTypesEtablissement: 'getListTypesEtablissement',
    }),
    // ---------------------------------------------------------
    getListTypesEtablissement() {
      this.action_getListTypesEtablissement()
        .then(res => {
          const resData = res.data.data
            ? res.data.data.map(a => ({ id: a.id, title: a.title }))
            : []

          this.typesEts = resData
        })
        .catch(err => { })
    },
    // getListTypesEtablissement() {
    //   this.action_getListTypesEtablissement()
    //     // .then((res) => {
    //     //   this.typesEts = res.data.data || [];
    //     // })

    //     .catch((err) => {});
    // },
    envoyer() {
      if (!this.selectedProfile) {
        this.noOperator = true
        return
      } this.noOperator = false
      if (!this.msgObjectSelected) {
        this.noObjectSelected = true
        return
      } this.noObjectSelected = false
      this.$refs.formRules.validate().then(async success => {
        if (success) {
          this.isSending = true
          console.log('this.objectMsg: 🩸🩸🩸 ', this.objectMsg)
          // ReCaptcha v3
          // (optional) Wait until recaptcha has been loaded.
          await this.$recaptchaLoaded()
          // Execute reCAPTCHA with action "login".
          const token = await this.$recaptcha('login')
          if (!token) {
            this.isLoading = false
            return
          }
          const formData = new FormData()

          // Append form data to the FormData object
          formData.append('firstname', this.objectMsg.nom)
          formData.append('lastname', this.objectMsg.prenom)
          formData.append('email', this.objectMsg.mail)
          formData.append('phone_number', this.objectMsg.numero)
          formData.append('object', this.objectMsg.sujet)
          formData.append('message', this.objectMsg.message)
          formData.append('culprit_fullname', this.objectMsg.culprit)
          formData.append('rating', this.objectMsg.rating)
          formData.append('profile_id', this.selectedProfile.id)
          formData.append('token', token)
          // Append files to the FormData object
          if (this.objectMsg.jointFiles) {
            for (const file of this.objectMsg.jointFiles) {
              formData.append('attached_files[]', file)
            }
          }

          this.action_createPlainte(formData)
            .then(res => {
              console.log('res: ', res)
              if (res.status === 201) {
                this.objectMsg = {
                  nom: '',
                  prenom: '',
                  jointFiles: null,
                  mail: '',
                  sujet: '',
                  message: '',
                  numero: '',
                  culprit: '',
                }
                this.selectedProfile = null

                // success alert
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Réclamation envoyée avec succès.',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                this.isSending = false

                // redition to home
                this.$router.replace({ name: 'home-public' })
              }
            })
            .catch(err => {
              console.log('err: ', err)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: err.message || 'Une erreur est survenue !',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
              this.isSending = false
            })
        }
      })
    },
    fetchEtablissementsProfiles(profileToSearch) {
      this.isSearchEtablissementProfiles = true
      const searchPayload = {
        name: profileToSearch,
      }
      this.action_searchEtablissement(searchPayload)
        .then(res => {
          // console.log('🥒🥒🥒', res.data.data);
          this.showListOfProfiles = true
          this.fetchedEtablissementProfiles = res.data.data
          this.isSearchEtablissementProfiles = false
        })
        .catch(err => {
          console.log('err: ', err)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue !',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.isSearchEtablissementProfiles = false
        })
    },
    removeSelectedProfile() {
      this.searchedProfile = ''
      this.fetchedEtablissementProfiles = []
      this.showListOfProfiles = false
      this.selectedProfile = null
      this.noOperator = false
      this.objectMsg.culprit = ''
    },
    selectedTheProfile(profile) {
      this.searchedProfile = ''
      this.fetchedEtablissementProfiles = []
      this.showListOfProfiles = false
      this.selectedProfile = profile
      this.objectMsg.culprit = ''
      this.noOperator = false
    },
    handleFileChange(event) {
      // Store selected files in the formData
      this.objectMsg.jointFiles = Array.from(event.target.files)
    },
    // loadSectors() {
    //   const currentParams = {
    //     page: this.paginationData.currentPage - 1,
    //     perPage: this.paginationData.perPage,
    //   };
    //   this.fetchSecteurs({ params: currentParams || null })
    //     .then((res) => {
    //       this.sectors = res.data.secteurs;
    //       this.sectorsTotal = res.data.total;
    //       this.sectors.forEach((currentValue, index, arr) => {
    //         this.sectorsCustom.push({
    //           nom: currentValue.nom,
    //           _id: currentValue._id,
    //         });
    //       });
    //       // console.log('res this.sectorsCustom: ', this.sectorsCustom)
    //     })
    //     .catch((err) => {
    //       this.isLoadingTableData = false;
    //       console.log(err);
    //     });
    // },
    showModal() {
      this.$refs['modal-info-show'].show()
    },
    hideModal() {
      this.$refs['modal-info-show'].hide()
    },
  },
}
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style scoped>
.contact {
  position: relative;
  width: 100%;
  /* min-height: 100vh; */
}

.contact-wrapper {
  margin: 0 auto;
  max-width: 1300px;
  height: 100%;
  /* min-height: 100vh; */

  padding: 3em 1em;
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr; */
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  gap: 3em;
}

.contact .back-image {
  position: absolute;
  inset: 0;
  z-index: -20;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hideInputOperator {
  display: none;
  position: absolute;
  top: -1000px;
}

.contact .filigrane-color {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: -10;

  background-color: #06250fb2;
}

.left-infos {
  color: #fff;
  text-shadow: 2px 2px 1px #00000090;
}

.left-infos h1 {
  color: #fff;
  font-weight: bold;
  font-size: 35px;
}

.left-infos h3 {
  color: #fff;
}

.left-infos p {
  margin: 1em 0;
  line-height: 30px;
  color: #fff;
  font-size: 16px;
}

.contact-form {
  border-radius: 1em;
  padding: 2em;
  background-color: #fff;
  min-width: 330px;
}

.contact-form .logo {
  max-width: 190px;
  display: block;
}

.contact-form .logo img {
  width: 190px;
}

.contact-form p {
  margin: 1em auto;
  font-size: 20px;
  text-align: center;
}

.contact-form h1 {
  margin: 0.5em auto 1.4em auto;
  font-size: 27px;
  text-align: center;
}

.contact-names-email {
  display: grid;
  gap: 0 2em;
}

.operator-search {
  position: relative;
}

.operator-spinner {
  position: absolute;
  top: 25%;
  right: 15px;
}

.custom-list-group {
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 45px;
  z-index: 10;
}

.custom-list-group:hover .list-item:hover {
  background-color: #0e6258;
  color: #fff;
}

.list-item {
  cursor: pointer;
}

.operatorCard {
  display: flex;
}

.closeOperatorCard {
  transform: translateY(-110%);
}

/* MEDIA QUERIES */
@media (min-width: 576px) {
  .contact-wrapper {
    flex-direction: row;
    padding: 4em 3em;
  }

  .contact-form {
    padding: 3em;
  }

  .contact-form {
    width: 100%;
    max-width: 650px;
  }

  .left-infos {
    width: 55%;
  }

  .contact-names-email {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 768px) {}

@media (min-width: 992px) {
  .left-infos h1 {
    font-size: 55px;
  }

  .left-infos p {
    font-size: 25px;
  }
}

@media (min-width: 1200px) {}

@media (min-width: 1400px) {}
</style>
